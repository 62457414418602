import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createProduct = createAsyncThunk(
  'product/create',
  async ({ state, dispatch, router }) => {
    try {
      const URL = `/product/admin/new`;
      const response = await post(URL, state);
      if (response) {
        router.push({
          pathname: paths.dashboard.products,
          query: {},
        });

        dispatch(activeSnack({ type: 'success', message: 'Product created successfully' }));
      } else {
        dispatch(activeSnack({ type: 'error', message: 'Something went wrong' }));
      }
      return response;
    } catch (error) {
      dispatch(
        activeSnack({
          type: 'error',
          message: error?.response?.data?.message || 'An error occurred',
        })
      );
      throw error;
    }
  }
);

export const getAllProduct = createAsyncThunk(
  'product/list',
  async ({
    page,
    search,
    limit,
    sort,
    dispatch,
    category,
    seller,
    status,
    district,
    state,
    city,
  }) => {
    try {
      const response = await get(
        `/product/admin/all?page=${page && page}&search=${search && search}&limit=${
          limit || 5
        }&sort=${sort || ''}&category=${category || ''}&seller=${seller || ''}&status=${
          status || ''
        }&district=${district || ''}&state=${state || ''}&city=${city || ''}`
      );
      console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getProductDetails = createAsyncThunk(
  'product/single',
  async ({ productId, dispatch }) => {
    try {
      console.log('ID:', productId);
      const response = await get(`/product/admin/${productId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateProductDetails = createAsyncThunk(
  'product/update',
  async ({ state, productId, dispatch, router, isQue }) => {
    try {
      const URL = `/product/admin/${productId}`;
      // cc
      const response = await put(URL, state);
      if (response) {
        dispatch(
          getAllProduct({
            page: 1,
            search: '',
            limit: sessionStorage.getItem('rowsPerPage_product'),
            sort: '',
            dispatch,
            category: '',
            seller: '',
            status:
              isQue && (response?.status === 'approved' || response?.status === 'declined')
                ? 'pending'
                : '',
            district: '',
            state: '',
            city: '',
          })
        );
        router.push({
          pathname: paths.dashboard.products,
          query: {},
        });

        dispatch(activeSnack({ type: 'success', message: 'Product updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteProduct = createAsyncThunk(
  'product/delete',
  async ({ productId, dispatch, router }) => {
    try {
      const response = await del(`/product/admin/${productId}`);
      if (response) {
        router.push({
          pathname: paths.dashboard.products,
          query: {},
        });
        dispatch(activeSnack({ type: 'success', message: 'Product deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
