import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';

import { useBoolean } from 'src/hooks/use-boolean';

import NoImg from 'src/assets/other/ImgNull.jpg';
// import { useGetRoles } from 'src/hooks/useHandleSessions';

// import { useGetEncryptLocalData } from 'src/hooks/useHandleSessions';

import { Stack, Switch } from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { deleteCity } from 'src/server/api/city';
import { updateUserDetails } from 'src/server/api/user';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function SellerTableRow({
  row,
  selected,
  handleEditClick,
  index,
  page,
  rowsPerPage,
}) {
  const { phone, name, profile_image, seller_details, _id, first_name } = row;
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const confirm = useBoolean();
  const popover = usePopover();
  const router = useRouter();
  // const { role } = useGetRoles();
  // const useStyles = makeStyles((theme) => ({
  //   switchColor: {
  //     '& .MuiSwitch-thumb': {
  //       color: 'green', // Customize the color here
  //     },
  //     '& .Mui-checked': {
  //       color: 'green', // Customize the color here
  //     },
  //   },
  // }));
  // const classes = useStyles();

  // const router = useRouter();
  // console.log(row);
  // const handleEditClick = (_id) => {
  //   navigate(paths.dashboard.sale_view(_id), {
  //     state: _id,
  //   });
  // };
  // eslint-disable-next-line no-shadow
  const handleViewClick = (_id) => {
    router.push({
      pathname: paths.dashboard.user_view(_id),
      query: { state: _id }, // Using query for state information
    });
  };
  // const handleEditClick = (id) => {
  //   if (row) {
  //     setOpenCountryId(row);
  //   }
  //   setOpenCreatePartnerCommissionDialog(true);
  // };
  // const handleDeleteConfirmClick = (_id) => {
  //   const credentials = {
  //     dispatch,
  //     saleId: _id,
  //   };
  //   dispatch(deleteSale(credentials));
  // };
  // const handleDeleteConfirmClick = (id) => {
  //   const credentials = {
  //     stateId: id,
  //     dispatch,
  //     // confirm.onFalse,
  //     // Add other necessary properties if needed
  //   };

  //   dispatch(deleteState(credentials));
  //   confirm.onFalse();
  // };
  const handleDeleteConfirmClick = (id) => {
    const credentials = {
      dispatch,
      cityId: id,
    };

    dispatch(deleteCity(credentials));
    confirm.onFalse();
  };
  const handleToggleActive = (isVerified, isDeleted) => {
    const UpdateCredentials = {
      state: {
        ...row,
        seller_details: {
          is_verified: isVerified,
        },
        is_deleted: isDeleted,
      },

      userId: _id,
      dispatch,
      // router,
    };

    dispatch(updateUserDetails(UpdateCredentials));
  };

  return (
    <>
      {/* {loading ? (
        <TableRow>
          <TableCell colSpan={colSpan + 1} align="center">
            <CircularProgress size="16px" />
          </TableCell>
        </TableRow>
      ) : ( */}
      <TableRow hover selected={selected}>
        <TableCell>
          <TableCell>
            <Stack sx={{ flexDirection: 'row', gap: '17px', alignItems: 'center' }}>
              <Stack>
                {profile_image ? (
                  // eslint-disable-next-line jsx-a11y/img-redundant-alt
                  <img
                    src={profile_image.url}
                    alt="First Image"
                    style={{
                      width: '50px',
                      height: '50px',
                      objectFit: 'cover',
                      borderRadius: '50%',
                    }}
                  />
                ) : (
                  // eslint-disable-next-line jsx-a11y/img-redundant-alt
                  <img
                    src={NoImg}
                    alt="No Image"
                    style={{
                      width: '50px',
                      height: '50px',
                      objectFit: 'cover',
                      borderRadius: '50%',
                    }}
                  />
                )}
              </Stack>
              <Stack>{first_name}</Stack>
            </Stack>
          </TableCell>
        </TableCell>

        <TableCell>{phone}</TableCell>
        <TableCell>
          {seller_details?.business_name ? seller_details?.business_name : '---'}
        </TableCell>
        <TableCell>{seller_details?.gst_number ? seller_details?.gst_number : '---'}</TableCell>

        <TableCell>
          {seller_details?.is_seller ? (
            <Switch
              color="default"
              checked={seller_details?.is_verified}
              onChange={(e) => handleToggleActive(e.target.checked, seller_details.is_deleted)}
            />
          ) : (
            <Switch
              color="default"
              checked={seller_details?.is_verified}
              onChange={(e) => handleToggleActive(e.target.checked, seller_details.is_deleted)}
              disabled
            />
          )}
        </TableCell>
        <TableCell
          sx={{ cursor: 'pointer' }}
          onClick={seller_details?.is_seller ? () => handleViewClick(_id) : undefined}
        >
          {' '}
          <Iconify
            icon="solar:eye-bold"
            style={{ color: seller_details?.is_seller ? undefined : '#919EAB' }}
          />{' '}
        </TableCell>
      </TableRow>
      {/* )} */}

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem onClick={() => handleEditClick(row)}>
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={`Are you sure want to delete '${name}' ?`}
        action={
          <Button variant="contained" color="error" onClick={() => handleDeleteConfirmClick(_id)}>
            Delete
          </Button>
        }
      />
    </>
  );
}

SellerTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  handleEditClick: PropTypes.any,
  index: PropTypes.any,
  rowsPerPage: PropTypes.any,
  page: PropTypes.any,
};
