// eslint-disable-next-line import/no-extraneous-dependencies
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet-async';

import { Container } from '@mui/material';

// ----------------------------------------------------------------------

export default function PrivacyPolicy() {
  const data = `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Privacy
  Policy</span></p><p><br></p><h2><span style="background-color:
  transparent; color: rgb(37, 61, 78);">PRIVACY NOTICE</span></h2><p><span
  style="background-color: transparent; color: rgb(0, 0, 0);">Last updated APRIL
  23 2024</span></p><ul><li><br></li><li><br></li></ul><p><span
  style="background-color: transparent; color: rgb(0, 0, 0);">This document
  serves as the Privacy Policy for the </span><strong style="background-color:
  transparent; color: rgb(0, 0, 0);">ila Areca </strong><span style="backgroundcolor: transparent; color: rgb(0, 0, 0);">&nbsp;application. It details the
  collection, use, and protection of personal information of users and emphasizes
  our commitment to safeguarding privacy.</span></p><p><br></p><p><br></
  p><p><br></p><p><strong style="color: rgb(37, 61, 78);">Questions or
  concerns? Reading this privacy notice will help you understand your privacy
  rights and choices. If you do not agree with our policies and practices, please
  do not use our Services. If you still have any questions or concerns, please
  contact us at </strong><strong style="color: rgb(255, 0, 0);">&nbsp;</
  strong><strong style="color: rgb(0, 0, 0);">alivakaptta@gmail.com</strong></
  p><p><br></p><p><span style="color: rgb(126, 126, 126);">SUMMARY OF
  KEY POINTS</span></p><p><br></p><p><span style="color: rgb(126, 126,
  126);">This summary provides key points from our privacy notice, but you can
  find out more details about any of these topics by clicking the link following
  each key point or by using our table of contents below to find the section you
  are looking for.</span></p><p><span style="color: rgb(126, 126, 126);">What
  personal information do we process? When you visit, use, or navigate our
  Services, we may process personal information depending on how you interact
  with&nbsp; </span><span style="color: rgb(37, 61, 78);">ila Arcade Application
  </span><span style="color: rgb(126, 126, 126);">and the Services, the choices
  you make, and the products and features you use.</span></p><p><span
  style="color: rgb(126, 126, 126);">Do we process any sensitive personal
  information? We do not process sensitive personal information.</span></
  p><p><span style="color: rgb(126, 126, 126);">Do we receive any information
  from third parties? We do not receive any information from third parties.</
  span></p><p><span style="color: rgb(126, 126, 126);">How do we process
  your information? We process your information to provide, improve, and
  administer our Services, communicate with you, for security and fraud
  prevention, and to comply with law. We may also process your information for
  other purposes with your consent. We process your information only when we
  have a valid legal reason to do so.</span></p><p><span style="color: rgb(126,
  126, 126);">In what situations and with which parties do we share personal
  information? We may share information in specific situations and with specific
  third parties.</span></p><p><span style="color: rgb(126, 126, 126);">How do
  we keep your information safe? We have organizational and technical processes
  and procedures in place to protect your personal information. However, no
  electronic transmission over the internet or information storage technology can
  be guaranteed to be 100% secure, so we cannot promise or guarantee that
  hackers, cybercriminals, or other unauthorized third parties will not be able to
  defeat our security and improperly collect, access, steal, or modify your 
  information.</span></p><p><span style="color: rgb(126, 126, 126);">What are
  your rights? Depending on where you are located geographically, the applicable
  privacy law may mean you have certain rights regarding your personal
  information.</span></p><p><span style="color: rgb(126, 126, 126);">How do
  you exercise your rights? The easiest way to exercise your rights is by filling out
  our data subject request form available here, or by contacting us. We will
  consider and act upon any request in accordance with applicable data
  protection laws.</span></p><p><br></p><p><br></p><h4><span
  style="background-color: transparent; color: rgb(37, 61, 78);">TABLE OF
  CONTENTS</span></h4><p><br></p><p><span style="background-color:
  transparent; color: rgb(126, 126, 126);">1. WHAT INFORMATION DO WE
  COLLECT?</span></p><p><span style="background-color: transparent; color:
  rgb(126, 126, 126);">2. HOW DO WE PROCESS YOUR INFORMATION?</
  span></p><p><span style="background-color: transparent; color: rgb(126,
  126, 126);">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
  INFORMATION?</span></p><p><span style="background-color: transparent;
  color: rgb(126, 126, 126);">4. DO WE USE COOKIES AND OTHER TRACKING
  TECHNOLOGIES?</span></p><p><span style="background-color: transparent;
  color: rgb(126, 126, 126);">5. HOW LONG DO WE KEEP YOUR INFORMATION?
  </span></p><p><span style="background-color: transparent; color: rgb(126,
  126, 126);">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">7. DO WE COLLECT INFORMATION FROM MINORS?</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">8. WHAT ARE YOUR PRIVACY RIGHTS?</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">9.
  CONTROLS FOR DO-NOT-TRACK FEATURES</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">10. DO
  CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">11. DO WE MAKE UPDATES TO THIS NOTICE?</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">12. HOW
  CAN YOU CONTACT US ABOUT THIS NOTICE?</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">13. HOW
  CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
  </span></p><h4><span style="background-color: transparent; color: rgb(37,
  61, 78);">1. WHAT INFORMATION DO WE COLLECT?</span></h4><p><br></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">Personal information you disclose to us</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">In Short: We
  collect personal information that you provide to us.</span></p><p><br></
  p><ul><li class="ql-align-justify"><span style="background-color:
  transparent;">Authentication: When you register an account on the ila - Areca
  App, we collect your phone number for authentication purposes. We use a onetime password (OTP) sent to your phone to verify your identity.</span></li><li
  class="ql-align-justify"><span style="background-color: transparent;">Market
  Value Section: The ila - Areca App provides market value information, including 
  current price, previous price, and predictions for various categories of areca.
  We collect and display this data based on your preferences and interactions
  with the App.</span></li><li class="ql-align-justify"><span
  style="background-color: transparent;">List Products: The App lists products
  based on region and date. To personalize your experience, we may collect your
  region information, including state, district, and city, which is saved locally on
  your device. You can change your region settings as needed.</span></li><li
  class="ql-align-justify"><span style="background-color:
  transparent;">Categories: You can browse areca products by categories. We
  may collect data on your category preferences to enhance your browsing
  experience.</span></li><li class="ql-align-justify"><span style="backgroundcolor: transparent;">Search: The App allows you to search for products by
  name. We may collect and process your search queries to provide relevant
  results.</span></li><li class="ql-align-justify"><span style="background-color:
  transparent;">Product Single View: When viewing a product, we may collect
  and display details such as product description, quantity, price range, seller
  information, and contact details.</span></li><li class="ql-align-justify"><span
  style="background-color: transparent;">Profile: When you create a profile on
  the Ila - Areca App, we collect information such as your business name, first
  name, last name, star rating, and verified badge status. You may also provide
  your address and location details, including state, district, and mandalam.</
  span></li><li class="ql-align-justify"><span style="background-color:
  transparent;">My Products: If you are a seller, you can list your products on the
  App. We collect product details such as name, description, quantity, price
  range, photos, and contact details. You can also choose the region where your
  product is available.</span></li></ul><p><br></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">Sensitive
  Information. We do not process sensitive information.</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">Application
  Data. If you use our application(s), we also may collect the following
  information if you choose to provide us with access or permission:</span></
  p><p><br></p><ul><li><br></li></ul><p><span style="background-color:
  transparent; color: rgb(126, 126, 126);">Mobile Device Data. We automatically
  collect device information (such as your mobile device ID, model, and
  manufacturer), operating system, version information and system configuration
  information, device and application identification numbers, browser type and
  version, hardware model Internet service provider and/or mobile carrier, and
  Internet Protocol (IP) address (or proxy server). If you are using our
  application(s), we may also collect information about the phone network
  associated with your mobile device, your mobile device’s operating system or
  platform, the type of mobile device you use, your mobile device’s unique device
  ID, and information about the features of our application(s) you accessed.</
  span></p><ul><li><br></li></ul><p><span style="background-color:
  transparent; color: rgb(126, 126, 126);">This information is primarily needed to
  maintain the security and operation of our application(s), for troubleshooting,
  and for our internal analytics and reporting purposes.</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">All personal 
  information that you provide to us must be true, complete, and accurate, and
  you must notify us of any changes to such personal information.</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">Information automatically collected</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">In Short:
  Some information — such as your Internet Protocol (IP) address and/or browser
  and device characteristics — is collected automatically when you visit our
  Services.</span></p><p><span style="background-color: transparent; color:
  rgb(126, 126, 126);">We automatically collect certain information when you
  visit, use, or navigate the Services. This information does not reveal your
  specific identity (like your name or contact information) but may include device
  and usage information, such as your IP address, browser and device
  characteristics, operating system, language preferences, referring URLs, device
  name, country, location, information about how and when you use our Services,
  and other technical information. This information is primarily needed to
  maintain the security and operation of our Services, and for our internal
  analytics and reporting purposes.</span></p><p><span style="backgroundcolor: transparent; color: rgb(126, 126, 126);">Like many businesses, we also
  collect information through cookies and similar technologies.</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">The information we collect includes:</span></p><ul><li><br></li></
  ul><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">Log and Usage Data. Log and usage data is service-related, diagnostic,
  usage, and performance information our servers automatically collect when you
  access or use our Services and which we record in log files. Depending on how
  you interact with us, this log data may include your IP address, device
  information, browser type, and settings and information about your activity in
  the Services (such as the date/time stamps associated with your usage, pages
  and files viewed, searches, and other actions you take such as which features
  you use), device event information (such as system activity, error reports
  (sometimes called 'crash dumps'), and hardware settings).</span></
  p><ul><li><br></li></ul><p><span style="background-color: transparent;
  color: rgb(126, 126, 126);">Device Data. We collect device data such as
  information about your computer, phone, tablet, or other device you use to
  access the Services. Depending on the device used, this device data may
  include information such as your IP address (or proxy server), device and
  application identification numbers, location, browser type, hardware model,
  Internet service provider and/or mobile carrier, operating system, and system
  configuration information.</span></p><p><br></p><h4><span
  style="background-color: transparent; color: rgb(37, 61, 78);">2. HOW DO WE
  PROCESS YOUR INFORMATION?</span></h4><p><br></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">In Short: We
  process your information to provide, improve, and administer our Services,
  communicate with you, for security and fraud prevention, and to comply with
  law. We may also process your information for other purposes with your
  consent.</span></p><p><span style="background-color: transparent; color:
  rgb(126, 126, 126);">We process your personal information for a variety of 
  reasons, depending on how you interact with our Services, including:</span></
  p><ul><li><br></li></ul><p><span style="background-color: transparent;
  color: rgb(126, 126, 126);">To facilitate account creation and authentication and
  otherwise manage user accounts. We may process your information so you can
  create and log in to your account, as well as keep your account in working
  order.</span></p><p><br></p><p><span style="background-color:
  transparent; color: rgb(126, 126, 126);">To evaluate and improve our Services,
  products, marketing, and your experience. We may process your information
  when we believe it is necessary to identify usage trends, determine the
  effectiveness of our promotional campaigns, and to evaluate and improve our
  Services, products, marketing, and your experience.</span></
  p><ul><li><br></li></ul><p><span style="background-color: transparent;
  color: rgb(126, 126, 126);">To deliver and facilitate delivery of services to the
  user. We may process your information to provide you with the requested
  service.</span></p><p><br></p><ul><li><br></li></ul><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">To comply
  with our legal obligations. We may process your information to comply with our
  legal obligations, respond to legal requests, and exercise, establish, or defend
  our legal rights.</span></p><p><br></p><h4><span style="background-color:
  transparent; color: rgb(37, 61, 78);">3. WHEN AND WITH WHOM DO WE
  SHARE YOUR PERSONAL INFORMATION?</span></h4><p><br></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">In Short: We
  may share information in specific situations described in this section.</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">We may need to share your personal information in the following
  situations:</span></p><p><span style="color: rgb(126, 126, 126);">Contact
  Seller: When you contact a seller through the ila - Areca App, we may share
  your contact details with the seller to facilitate communication.</span></
  p><ul><li><br></li></ul><p><br></p><h4><span style="background-color:
  transparent; color: rgb(37, 61, 78);">4. DO WE USE COOKIES AND OTHER
  TRACKING TECHNOLOGIES?</span></h4><p><br></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">In Short: We
  may use cookies and other tracking technologies to collect and store your
  information.</span></p><p><span style="background-color: transparent;
  color: rgb(126, 126, 126);">We may use cookies and similar tracking
  technologies (like web beacons and pixels) to access or store information.
  Specific information about how we use such technologies and how you can
  refuse certain cookies is set out in our Cookie Notice.</span></p><p><br></
  p><p><br></p><p><br></p><h4><span style="background-color:
  transparent; color: rgb(37, 61, 78);">5. HOW LONG DO WE KEEP YOUR
  INFORMATION?</span></h4><p><br></p><p><span style="background-color:
  transparent; color: rgb(126, 126, 126);">In Short: We keep your information for
  as long as necessary to fulfill the purposes outlined in this privacy notice unless
  otherwise required by law.</span></p><p><span style="background-color:
  transparent; color: rgb(126, 126, 126);">We will only keep your personal
  information for as long as it is necessary for the purposes set out in this privacy
  notice, unless a longer retention period is required or permitted by law (such as 
  tax, accounting, or other legal requirements). No purpose in this notice will
  require us to keep your personal information for longer than the period of time
  in which users have an account with us.</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">When we
  have no ongoing legitimate business need to process your personal
  information, we will either delete or anonymise such information, or, if this is
  not possible (for example, because your personal information has been stored
  in backup archives), then we will securely store your personal information and
  isolate it from any further processing until deletion is possible.</span></
  p><p><br></p><h4><span style="background-color: transparent; color:
  rgb(37, 61, 78);">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></
  h4><p><br></p><p><span style="background-color: transparent; color:
  rgb(126, 126, 126);">In Short: We aim to protect your personal information
  through a system of organizational and technical security measures.</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">We have implemented appropriate and reasonable technical and
  organizational security measures designed to protect the security of any
  personal information we process. However, despite our safeguards and efforts
  to secure your information, no electronic transmission over the Internet or
  information storage technology can be guaranteed to be 100% secure, so we
  cannot promise or guarantee that hackers, cybercriminals, or other
  unauthorized third parties will not be able to defeat our security and improperly
  collect, access, steal, or modify your information. Although we will do our best
  to protect your personal information, transmission of personal information to
  and from our Services is at your own risk. You should only access the Services
  within a secure environment.</span></p><p><span style="color: rgb(13, 13,
  13);">Data Retention: </span><span style="color: rgb(126, 126, 126);">We
  retain your personal information only for as long as necessary to fulfill the
  purposes described in this Privacy Policy or as required by law.</span></
  p><p><br></p><h4><span style="background-color: transparent; color:
  rgb(37, 61, 78);">7. DO WE COLLECT INFORMATION FROM MINORS?</span></
  h4><p><br></p><p><span style="background-color: transparent; color:
  rgb(126, 126, 126);">In Short: We do not knowingly collect data from or market
  to children under 18 years of age.</span></p><p><span style="backgroundcolor: transparent; color: rgb(126, 126, 126);">We do not knowingly solicit data
  from or market to children under 18 years of age. By using the Services, you
  represent that you are at least 18 or that you are the parent or guardian of such
  a minor and consent to such minor dependent’s use of the Services. If we learn
  that personal information from users less than 18 years of age has been
  collected, we will deactivate the account and take reasonable measures to
  promptly delete such data from our records. If you become aware of any data
  we may have collected from children under age 18, please contact us at
  alivakaptta@gmail.com</span></p><p><br></p><h4><span
  style="background-color: transparent; color: rgb(37, 61, 78);">8. WHAT ARE
  YOUR PRIVACY RIGHTS?</span></h4><p><br></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">In Short: You
  may review, change, or terminate your account at any time.</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">If you are located in the EEA or UK and you believe we are unlawfully
  processing your personal information, you also have the right to complain to
  your local data protection supervisory authority. You can find their contact
  details here: https://ec.europa.eu/justice/data-protection/bodies/authorities/
  index_en.htm.</span></p><p><span style="background-color: transparent;
  color: rgb(126, 126, 126);">If you are located in Switzerland, the contact details
  for the data protection authorities are available here: https://
  www.edoeb.admin.ch/edoeb/en/home.html.</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">Withdrawing
  your consent: If we are relying on your consent to process your personal
  information, which may be express and/or implied consent depending on the
  applicable law, you have the right to withdraw your consent at any time. You
  can withdraw your consent at any time by contacting us by using the contact
  details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS
  NOTICE?' below.</span></p><p><span style="background-color: transparent;
  color: rgb(126, 126, 126);">However, please note that this will not affect the
  lawfulness of the processing before its withdrawal nor, when applicable law
  allows, will it affect the processing of your personal information conducted in
  reliance on lawful processing grounds other than consent.</span></
  p><p><br></p><p><br></p><p><span style="background-color: transparent;
  color: rgb(126, 126, 126);">Account Information</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">If you would
  at any time like to review or change the information in your account or
  terminate your account, you can:</span></p><ul><li><br></li></ul><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">Log in to
  your account settings and update your user account.</span></
  p><ul><li><br></li></ul><p><span style="background-color: transparent;
  color: rgb(126, 126, 126);">Contact us using the contact information
  provided.</span></p><p><span style="background-color: transparent; color:
  rgb(126, 126, 126);">Upon your request to terminate your account, we will
  deactivate or delete your account and information from our active databases.
  However, we may retain some information in our files to prevent fraud,
  troubleshoot problems, assist with any investigations, enforce our legal terms
  and/or comply with applicable legal requirements.</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">Cookies and
  similar technologies: Most Web browsers are set to accept cookies by default.
  If you prefer, you can usually choose to set your browser to remove cookies and
  to reject cookies. If you choose to remove cookies or reject cookies, this could
  affect certain features or services of our Services. To opt out of interest-based
  advertising by advertisers on our Services visit http://www.aboutads.info/
  choices/.</span></p><p><span style="background-color: transparent; color:
  rgb(126, 126, 126);">If you have questions or comments about your privacy
  rights, you may email us at alivakaptta@gmail.com</span></p><p><strong
  style="background-color: transparent; color: rgb(0, 0, 0);">9. CONTROLS FOR
  DO-NOT-TRACK FEATURES</strong></p><p><span style="background-color:
  transparent; color: rgb(126, 126, 126);">Most web browsers and some mobile 
  operating systems and mobile applications include a Do-Not-Track ('DNT')
  feature or setting you can activate to signal your privacy preference not to have
  data about your online browsing activities monitored and collected. At this
  stage no uniform technology standard for recognising and implementing DNT
  signals has been finalized. As such, we do not currently respond to DNT
  browser signals or any other mechanism that automatically communicates your
  choice not to be tracked online. If a standard for online tracking is adopted that
  we must follow in the future, we will inform you about that practice in a revised
  version of this privacy notice.</span></p><p><br></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">10. DO
  CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">In Short: Yes, if you are a resident of California, you are granted specific
  rights regarding access to your personal information.</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">California
  Civil Code Section 1798.83, also known as the 'Shine The Light' law, permits
  our users who are California residents to request and obtain from us, once a
  year and free of charge, information about categories of personal information
  (if any) we disclosed to third parties for direct marketing purposes and the
  names and addresses of all third parties with which we shared personal
  information in the immediately preceding calendar year. If you are a California
  resident and would like to make such a request, please submit your request in
  writing to us using the contact information provided below.</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">If you are under 18 years of age, reside in California, and have a
  registered account with Services, you have the right to request removal of
  unwanted data that you publicly post on the Services. To request removal of
  such data, please contact us using the contact information provided below and
  include the email address associated with your account and a statement that
  you reside in California. We will make sure the data is not publicly displayed on
  the Services, but please be aware that the data may not be completely or
  comprehensively removed from all our systems (e.g. backups, etc.).</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">CCPA Privacy Notice</span></p><p><span style="background-color:
  transparent; color: rgb(126, 126, 126);">The California Code of Regulations
  defines a 'resident' as:</span></p><p><span style="background-color:
  transparent; color: rgb(126, 126, 126);">(1) every individual who is in the State
  of California for other than a temporary or transitory purpose and</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">(2) every individual who is domiciled in the State of California who is
  outside the State of California for a temporary or transitory purpose</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">All other individuals are defined as 'non-residents'.</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">If this definition of 'resident' applies to you, we must adhere to certain
  rights and obligations regarding your personal information.</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126, 
  126);">What categories of personal information do we collect?</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">We have collected the following categories of personal information in
  the past twelve (12) months:</span></p><p><span style="background-color:
  transparent; color: rgb(126, 126, 126);">Category</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">Examples</
  span></p><p><span style="background-color: transparent; color: rgb(126,
  126, 126);">Collected</span></p><p><span style="background-color:
  transparent; color: rgb(126, 126, 126);">A. Identifiers</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">Contact
  details, such as real name, alias, postal address, telephone or mobile contact
  number, unique personal identifier, online identifier, Internet Protocol address,
  email address, and account name</span></p><p><span style="backgroundcolor: transparent; color: rgb(126, 126, 126);">NO</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">B. Personal
  information categories listed in the California Customer Records statute</
  span></p><p><span style="background-color: transparent; color: rgb(126,
  126, 126);">Name, contact information, education, employment, employment
  history, and financial information</span></p><p><span style="backgroundcolor: transparent; color: rgb(126, 126, 126);">YES</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">C. Protected
  classification characteristics under California or federal law</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">Gender and date of birth</span></p><p><span style="backgroundcolor: transparent; color: rgb(126, 126, 126);">NO</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">D.
  Commercial information</span></p><p><span style="background-color:
  transparent; color: rgb(126, 126, 126);">Transaction information, purchase
  history, financial details, and payment information</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">YES</
  span></p><p><span style="background-color: transparent; color: rgb(126,
  126, 126);">E. Biometric information</span></p><p><span style="backgroundcolor: transparent; color: rgb(126, 126, 126);">Fingerprints and voiceprints</
  span></p><p><span style="background-color: transparent; color: rgb(126,
  126, 126);">NO</span></p><p><span style="background-color: transparent;
  color: rgb(126, 126, 126);">F. Internet or other similar network activity</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">Browsing history, search history, online behavior, interest data, and
  interactions with our and other websites, applications, systems, and
  advertisements</span></p><p><span style="background-color: transparent;
  color: rgb(126, 126, 126);">NO</span></p><p><span style="background-color:
  transparent; color: rgb(126, 126, 126);">G. Geolocation data</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">Device location</span></p><p><span style="background-color:
  transparent; color: rgb(126, 126, 126);">YES</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">H. Audio,
  electronic, visual, thermal, olfactory, or similar information</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">Images and audio, video or call recordings created in connection with
  our business activities</span></p><p><span style="background-color:
  transparent; color: rgb(126, 126, 126);">NO</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">I.
  Professional or employment-related information</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">Business
  contact details in order to provide you our Services at a business level or job
  title, work history, and professional qualifications if you apply for a job with us</
  span></p><p><span style="background-color: transparent; color: rgb(126,
  126, 126);">NO</span></p><p><span style="background-color: transparent;
  color: rgb(126, 126, 126);">J. Education Information</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">Student
  records and directory information</span></p><p><span style="backgroundcolor: transparent; color: rgb(126, 126, 126);">NO</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">K. Inferences
  drawn from other personal information</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">Inferences
  drawn from any of the collected personal information listed above to create a
  profile or summary about, for example, an individual’s preferences and
  characteristics</span></p><p><span style="background-color: transparent;
  color: rgb(126, 126, 126);">NO</span></p><p><span style="background-color:
  transparent; color: rgb(126, 126, 126);">We may also collect other personal
  information outside of these categories through instances where you interact
  with us in person, online, or by phone or mail in the context of:</span></
  p><ul><li><br></li></ul><p><span style="background-color: transparent;
  color: rgb(126, 126, 126);">Receiving help through our customer support
  channels;</span></p><ul><li><br></li></ul><p><span style="backgroundcolor: transparent; color: rgb(126, 126, 126);">Participation in customer surveys
  or contests; and</span></p><ul><li><br></li></ul><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">Facilitation in
  the delivery of our Services and to respond to your inquiries.</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">How do we use and share your personal information?</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">More information about our data collection and sharing practices can be
  found in this privacy notice.</span></p><p><span style="background-color:
  transparent; color: rgb(126, 126, 126);">You may contact us by email at
  alivakaptta@gmail.com, or by referring to the contact details at the bottom of
  this document.</span></p><p><span style="background-color: transparent;
  color: rgb(126, 126, 126);">If you are using an authorized agent to exercise your
  right to opt out we may deny a request if the authorized agent does not submit
  proof that they have been validly authorized to act on your behalf.</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">Will your information be shared with anyone else?</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">We may
  disclose your personal information with our service providers pursuant to a 
  written contract between us and each service provider. Each service provider is
  a for-profit entity that processes the information on our behalf.</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">We may use your personal information for our own business purposes,
  such as for undertaking internal research for technological development and
  demonstration. This is not considered to be 'selling' your personal
  information.</span></p><p><span style="background-color: transparent;
  color: rgb(126, 126, 126);">Ila Areca has not disclosed or sold any personal
  information to third parties for a business or commercial purpose in the
  preceding twelve (12) months. Ila Areca&nbsp; App will not sell personal
  information in the future belonging to website visitors, users, and other
  consumers.</span></p><p><span style="background-color: transparent;
  color: rgb(126, 126, 126);">Your rights with respect to your personal data</
  span></p><p><br></p><h4><span style="background-color: transparent;
  color: rgb(37, 61, 78);">Right to request deletion of the data — Request to
  delete</span></h4><p><span style="background-color: transparent; color:
  rgb(126, 126, 126);">You can ask for the deletion of your personal information.
  If you ask us to delete your personal information, we will respect your request
  and delete your personal information, subject to certain exceptions provided by
  law, such as (but not limited to) the exercise by another consumer of his or her
  right to free speech, our compliance requirements resulting from a legal
  obligation, or any processing that may be required to protect against illegal
  activities.</span></p><p><span style="background-color: transparent; color:
  rgb(126, 126, 126);">Right to be informed — Request to know</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">Depending on the circumstances, you have a right to know:</span></
  p><ul><li><br></li></ul><p><span style="background-color: transparent;
  color: rgb(126, 126, 126);">whether we collect and use your personal
  information;</span></p><ul><li><br></li></ul><p><span style="backgroundcolor: transparent; color: rgb(126, 126, 126);">the categories of personal
  information that we collect;</span></p><ul><li><br></li></ul><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">the purposes
  for which the collected personal information is used;</span></
  p><ul><li><br></li></ul><p><span style="background-color: transparent;
  color: rgb(126, 126, 126);">whether we sell your personal information to third
  parties;</span></p><ul><li><br></li></ul><p><span style="background-color:
  transparent; color: rgb(126, 126, 126);">the categories of personal information
  that we sold or disclosed for a business purpose;</span></p><ul><li><br></
  li></ul><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">the categories of third parties to whom the personal information was
  sold or disclosed for a business purpose; and</span></p><ul><li><br></li></
  ul><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">the business or commercial purpose for collecting or selling personal
  information.</span></p><p><span style="background-color: transparent;
  color: rgb(126, 126, 126);">In accordance with applicable law, we are not
  obligated to provide or delete consumer information that is de-identified in
  response to a consumer request or to re-identify individual data to verify a 
  consumer request.</span></p><p><span style="background-color:
  transparent; color: rgb(126, 126, 126);">Right to Non-Discrimination for the
  Exercise of a Consumer’s Privacy Rights</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">We will not
  discriminate against you if you exercise your privacy rights.</span></
  p><p><br></p><p><span style="background-color: transparent; color:
  rgb(126, 126, 126);">Verification process</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">Upon
  receiving your request, we will need to verify your identity to determine you are
  the same person about whom we have the information in our system. These
  verification efforts require us to ask you to provide information so that we can
  match it with information you have previously provided us. For instance,
  depending on the type of request you submit, we may ask you to provide
  certain information so that we can match the information you provide with the
  information we already have on file, or we may contact you through a
  communication method (e.g. phone or email) that you have previously provided
  to us. We may also use other verification methods as the circumstances
  dictate.</span></p><p><span style="background-color: transparent; color:
  rgb(126, 126, 126);">We will only use personal information provided in your
  request to verify your identity or authority to make the request. To the extent
  possible, we will avoid requesting additional information from you for the
  purposes of verification. However, if we cannot verify your identity from the
  information already maintained by us, we may request that you provide
  additional information for the purposes of verifying your identity and for
  security or fraud-prevention purposes. We will delete such additionally
  provided information as soon as we finish verifying you.</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">Other
  privacy rights</span></p><ul><li><br></li></ul><p><span style="backgroundcolor: transparent; color: rgb(126, 126, 126);">You may object to the processing
  of your personal information.</span></p><ul><li><br></li></ul><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">You may
  request correction of your personal data if it is incorrect or no longer relevant,
  or ask to restrict the processing of the information.</span></p><ul><li><br></
  li></ul><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">You can designate an authorized agent to make a request under the
  CCPA on your behalf. We may deny a request from an authorized agent that
  does not submit proof that they have been validly authorized to act on your
  behalf in accordance with the CCPA.</span></p><ul><li><br></li></
  ul><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">You may request to opt out from future selling of your personal
  information to third parties. Upon receiving an opt-out request, we will act upon
  the request as soon as feasibly possible, but no later than fifteen (15) days from
  the date of the request submission.</span></p><p><span style="backgroundcolor: transparent; color: rgb(126, 126, 126);">To exercise these rights, you can
  contact us by email at alivakaptta@gmail.com or by referring to the contact
  details at the bottom of this document. If you have a complaint about how we
  handle your data, we would like to hear from you.</span></p><h4><span 
  style="background-color: transparent; color: rgb(37, 61, 78);">11. DO WE MAKE
  UPDATES TO THIS NOTICE?</span></h4><p><br></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">In Short: Yes,
  we will update this notice as necessary to stay compliant with relevant laws.</
  span></p><p><span style="background-color: transparent; color: rgb(126,
  126, 126);">We may update this privacy notice from time to time. The updated
  version will be indicated by an updated 'Revised' date and the updated version
  will be effective as soon as it is accessible. If we make material changes to this
  privacy notice, we may notify you either by prominently posting a notice of such
  changes or by directly sending you a notification. We encourage you to review
  this privacy notice frequently to be informed of how we are protecting your
  information.</span></p><p><br></p><h4><span style="background-color:
  transparent; color: rgb(37, 61, 78);">12. HOW CAN YOU CONTACT US ABOUT
  THIS NOTICE?</span></h4><p><span style="background-color: transparent;
  color: rgb(126, 126, 126);">If you have questions or comments about this
  notice, you may email us at alivakaptta@gmail.com or by post to:</span></
  p><p><span style="background-color: transparent; color: rgb(126, 126,
  126);">Ali&nbsp;</span></p><p><span style="background-color: transparent;
  color: rgb(126, 126, 126);">Vakapatta House</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">P.O
  Chathabgotupuram&nbsp;</span></p><p><span style="background-color:
  transparent; color: rgb(126, 126, 126);">Pin 679 328</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126,
  126);">Malappuram&nbsp;</span></p><p><span style="background-color:
  transparent; color: rgb(126, 126, 126);">Kerala&nbsp;</span></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">India</
  span></p><p><br></p><h4><span style="background-color: transparent;
  color: rgb(37, 61, 78);">13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE
  DATA WE COLLECT FROM YOU?</span></h4><p><br></p><p><span
  style="background-color: transparent; color: rgb(126, 126, 126);">Based on the
  applicable laws of your country, you may have the right to request access to the
  personal information we collect from you, change that information, or delete it.
  To request to review, update, or delete your personal information, please
  contact </span><span style="color: rgb(126, 126,
  126);">alivakaptta@gmail.com</span></p><p><br></p><p><br></
  p><p><br></p><p><br></p>`;
  return (
    <>
      <Helmet title="Privacy Policy" />
      <Container maxWidth="xl" sx={{ py: 3 }}>
        {parse(data)}
      </Container>
    </>
  );
}
