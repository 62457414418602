import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createMarketPlace = createAsyncThunk(
  'market-place/create',
  async ({ state, dispatch }) => {
    try {
      const URL = `/market-place/admin/new`;
      const response = await post(URL, state);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Market Place created successfully' }));
      } else {
        dispatch(activeSnack({ type: 'error', message: 'Something went wrong' }));
      }
      return response;
    } catch (error) {
      dispatch(
        activeSnack({
          type: 'error',
          message: error?.response?.data?.message || 'An error occurred',
        })
      );
      throw error;
    }
  }
);

export const getAllMarketPlace = createAsyncThunk(
  'market-place/list',
  async ({
    page,
    search,
    limit,
    sort,
    dispatch,
    category,
    seller,
    status,
    district,
    state,
    city,
  }) => {
    try {
      const response = await get(
        `/market-place/admin/all?page=${page && page}&search=${search && search}&limit=${
          limit || 25
        }&sort=${sort || ''}&category=${category || ''}&seller=${seller || ''}&status=${
          status || ''
        }&district=${district || ''}&state=${state || ''}&city=${city || ''}`
      );
      console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getMarketPlaceDetails = createAsyncThunk(
  'market-place/single',
  async ({ marketPlaceId, dispatch }) => {
    try {
      console.log('ID:', marketPlaceId);
      const response = await get(`/market-place/admin/${marketPlaceId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateMarketPlaceDetails = createAsyncThunk(
  'market-place/update',
  async ({ state, marketPlaceId, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/market-place/admin/${marketPlaceId}`;
      // cc
      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        // Uncomment the next line if you intend to redirect
        // router.push({
        //   pathname: paths.dashboard.products,
        //   query: {},
        // });

        dispatch(activeSnack({ type: 'success', message: 'Market Place updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteMarketPlace = createAsyncThunk(
  'market-place/delete',
  async ({ marketPlaceId, dispatch }) => {
    try {
      const response = await del(`/market-place/admin/${marketPlaceId}`);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Market Place deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
