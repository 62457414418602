import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import { createCity, getAllCity, deleteCity, getCityDetails, updateCityDetails } from '../api/city';

export const citySlice = createSlice({
  name: 'city',
  initialState: {
    loading: false,
    error: {},
    // staff
    citys: {},
    staffDropList: [],
    CityDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create staff details
    [createCity.pending]: (state) => {
      state.loading = true;
    },
    [createCity.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.citys;
      const newCityList = jsonState?.citys;

      const modifiedCityList = {
        ...jsonState,
        citys: Array.isArray(newCityList) ? [action.payload, ...newCityList] : [action.payload],
      };
      state.loading = false;
      state.CityDetails = action.payload;
      state.citys = modifiedCityList;
      state.error = {};
    },
    [createCity.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all districts
    [getAllCity.pending]: (state) => {
      state.loading = true;
    },
    [getAllCity.fulfilled]: (state, action) => {
      state.loading = false;
      state.citys = action.payload;
      state.error = {};
    },
    [getAllCity.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single staff details
    [getCityDetails.pending]: (state) => {
      state.loading = true;
    },
    [getCityDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.CityDetails = action.payload;
      state.error = {};
    },
    [getCityDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update staff details
    [updateCityDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateCityDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.CityDetails = action.payload;
    //   state.error = {};
    // },
    [updateCityDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.citys;
      const modifiedCityList = {
        ...jsonState,
        citys: jsonState.citys?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
      };

      state.loading = false;
      // state.districts = modifiedCityList;
      state.CityDetails = action.payload;
      state.city = modifiedCityList;
      state.error = {};
    },
    // [updateCityDetails.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   const jsonState = covertToJSON(state)?.districts;
    //   console.log(jsonState)
    //   const modifiedCityList = {
    //     ...jsonState,
    //     countries: jsonState.countries?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
    //     // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
    //   };
    //   state.loading = false;
    //   state.CityDetails = action.payload;
    //   state.partnerType = modifiedCityList;
    //   state.error = {};
    // },
    [updateCityDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteCity.pending]: (state) => {
      state.loading = true;
    },
    [deleteCity.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.citys;
      const modifiedCityList = {
        ...jsonState,
        citys: jsonState.citys?.filter((schoolDivisions) => schoolDivisions._id !== action.payload),
      };

      state.loading = false;
      state.citys = modifiedCityList;
      state.error = {};
    },
    [deleteCity.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = citySlice.actions;

export default citySlice.reducer;
