import { createSlice } from '@reduxjs/toolkit';

import {
  getDashboardData,
  getProgressionGraphData,
  getSidebarItemCountData,
  getPartnerSalesChartData,
  getPartnerEarningChartData,
  getPartnerBalanceChartData,
} from '../api/dashboard';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    error: {},
    loading: false,
    chartLoading: false,
    dashboardData: {},
    earningsChart: [],
    salesChart: [],
    balanceChart: [],
    progressionData: [],
    sidebarCounts: {},
  },
  reducers: {
    clearDashboardError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [getSidebarItemCountData.pending]: (state) => {
      state.loading = true;
    },
    [getSidebarItemCountData.fulfilled]: (state, action) => {
      state.loading = false;
      state.sidebarCounts = action.payload;
      state.error = {};
    },
    [getSidebarItemCountData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [getDashboardData.pending]: (state) => {
      state.loading = true;
    },
    [getDashboardData.fulfilled]: (state, action) => {
      state.loading = false;
      state.dashboardData = action.payload;
      state.error = {};
    },
    [getDashboardData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [getPartnerEarningChartData.pending]: (state) => {
      state.chartLoading = true;
    },
    [getPartnerEarningChartData.fulfilled]: (state, action) => {
      state.chartLoading = false;
      state.earningsChart = action.payload;
      state.error = {};
    },
    [getPartnerEarningChartData.rejected]: (state, action) => {
      state.chartLoading = false;
      state.error = action.error;
    },
    [getPartnerSalesChartData.pending]: (state) => {
      state.chartLoading = true;
    },
    [getPartnerSalesChartData.fulfilled]: (state, action) => {
      state.chartLoading = false;
      state.salesChart = action.payload;
      state.error = {};
    },
    [getPartnerSalesChartData.rejected]: (state, action) => {
      state.chartLoading = false;
      state.error = action.error;
    },

    [getPartnerBalanceChartData.pending]: (state) => {
      state.chartLoading = true;
    },
    [getPartnerBalanceChartData.fulfilled]: (state, action) => {
      state.chartLoading = false;
      state.balanceChart = action.payload;
      state.error = {};
    },
    [getPartnerBalanceChartData.rejected]: (state, action) => {
      state.chartLoading = false;
      state.error = action.error;
    },

    [getProgressionGraphData.pending]: (state) => {
      state.chartLoading = true;
    },
    [getProgressionGraphData.fulfilled]: (state, action) => {
      state.chartLoading = false;
      state.progressionData = action.payload;
      state.error = {};
    },
    [getProgressionGraphData.rejected]: (state, action) => {
      state.chartLoading = false;
      state.error = action.error;
    },
  },
});
export default dashboardSlice.reducer;
