import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createMarketPlace,
  getAllMarketPlace,
  deleteMarketPlace,
  getMarketPlaceDetails,
  updateMarketPlaceDetails,
} from '../api/marketPlace';

export const marketPlaceSlice = createSlice({
  name: 'marketPlace',
  initialState: {
    loading: false,
    error: {},
    marketplaces: {},
    marketPlaceDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create product details
    [createMarketPlace.pending]: (state) => {
      state.loading = true;
    },
    [createMarketPlace.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.marketplaces;
      const newProductList = jsonState?.marketplaces;

      const modifiedProductList = {
        ...jsonState,
        marketplaces: Array.isArray(newProductList)
          ? [action.payload, ...newProductList]
          : [action.payload],
      };
      state.loading = false;
      state.marketPlaceDetails = action.payload;
      state.marketplaces = modifiedProductList;
      state.error = {};
    },
    [createMarketPlace.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all product
    [getAllMarketPlace.pending]: (state) => {
      state.loading = true;
    },
    [getAllMarketPlace.fulfilled]: (state, action) => {
      state.loading = false;
      state.marketplaces = action.payload;
      state.error = {};
    },
    [getAllMarketPlace.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single product details
    [getMarketPlaceDetails.pending]: (state) => {
      state.loading = true;
    },
    [getMarketPlaceDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.marketPlaceDetails = action.payload;
      state.error = {};
    },
    [getMarketPlaceDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update product details
    [updateMarketPlaceDetails.pending]: (state) => {
      state.loading = true;
    },

    [updateMarketPlaceDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.marketplaces;
      const modifiedProductList = {
        ...jsonState,
        marketplaces: jsonState.marketplaces?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      state.marketPlaceDetails = action.payload;
      state.marketplaces = modifiedProductList;
      state.error = {};
    },

    [updateMarketPlaceDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // delete product
    [deleteMarketPlace.pending]: (state) => {
      state.loading = true;
    },
    [deleteMarketPlace.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.marketplaces;
      const modifiedProductList = {
        ...jsonState,
        marketplaces: jsonState.marketplaces?.filter((product) => product._id !== action.payload),
      };

      state.loading = false;
      state.marketplaces = modifiedProductList;
      state.error = {};
    },
    [deleteMarketPlace.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = marketPlaceSlice.actions;

export default marketPlaceSlice.reducer;
