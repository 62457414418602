import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createUser,
  deleteUser,
  getAllUsers,
  getUserDetails,
  updateUserDetails,
} from '../api/user';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    loading: false,
    error: {},
    users: {},
    userDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // get all Users
    [getAllUsers.pending]: (state) => {
      state.loading = true;
    },
    [getAllUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.users = action.payload;
      state.error = {};
    },
    [getAllUsers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // create user

    [createUser.pending]: (state) => {
      state.loading = true;
    },
    [createUser.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.users;
      const newUserList = jsonState?.users;

      const modifiedUserList = {
        ...jsonState,
        users: Array.isArray(newUserList) ? [action.payload, ...newUserList] : [action.payload],
      };
      state.loading = false;
      state.userDetails = action.payload;
      state.users = modifiedUserList;
      state.error = {};
    },
    [createUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // get single user details
    [getUserDetails.pending]: (state) => {
      state.loading = true;
    },
    [getUserDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.userDetails = action.payload;
      state.error = {};
    },
    [getUserDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update user details
    [updateUserDetails.pending]: (state) => {
      state.loading = true;
    },

    [updateUserDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.users;
      const modifiedUserList = {
        ...jsonState,
        users: jsonState.users?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
      };

      state.loading = false;
      state.userDetails = action.payload;
      state.users = modifiedUserList;
      state.error = {};
    },
    [updateUserDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // delete user
    [deleteUser.pending]: (state) => {
      state.loading = true;
    },

    [deleteUser.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.users;
      const modifiedUserList = {
        ...jsonState,
        users: jsonState.users?.filter((user) => user._id !== action.payload),
      };

      state.loading = false;
      state.users = modifiedUserList;
      state.error = {};
    },
    [deleteUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = userSlice.actions;

export default userSlice.reducer;
