import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createCategory,
  getAllCategory,
  deleteCategory,
  getCategoryDetails,
  getAllMainCategory,
  updateCategoryDetails,
} from '../api/category';

export const categorySlice = createSlice({
  name: 'category',
  initialState: {
    loading: false,
    error: {},
    // staff
    categories: {},

    subcategories: {},
    staffDropList: [],
    CategoryDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create staff details
    [createCategory.pending]: (state) => {
      state.loading = true;
    },
    [createCategory.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.subcategories;
      const newDistrictsList = jsonState?.subcategories;

      const modifiedDistrictList = {
        ...jsonState,
        subcategories: Array.isArray(newDistrictsList)
          ? [...newDistrictsList, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.CategoryDetails = action.payload;
      state.subcategories = modifiedDistrictList;
      state.error = {};
    },
    [createCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all subcategories
    [getAllCategory.pending]: (state) => {
      state.loading = true;
    },
    [getAllCategory.fulfilled]: (state, action) => {
      state.loading = false;
      state.subcategories = action.payload;
      state.error = {};
    },
    [getAllCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all main subcategories
    [getAllMainCategory.pending]: (state) => {
      state.loading = true;
    },
    [getAllMainCategory.fulfilled]: (state, action) => {
      state.loading = false;
      state.categories = action.payload;
      state.error = {};
    },
    [getAllMainCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single staff details
    [getCategoryDetails.pending]: (state) => {
      state.loading = true;
    },
    [getCategoryDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.CategoryDetails = action.payload;
      state.error = {};
    },
    [getCategoryDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update staff details
    [updateCategoryDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateCategoryDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.CategoryDetails = action.payload;
    //   state.error = {};
    // },
    [updateCategoryDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.subcategories;
      const modifiedDistrictList = {
        ...jsonState,
        subcategories: jsonState.subcategories?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      // state.subcategories = modifiedDistrictList;
      state.CategoryDetails = action.payload;
      state.subcategories = modifiedDistrictList;
      state.error = {};
    },
    // [updateCategoryDetails.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   const jsonState = covertToJSON(state)?.subcategories;
    //   console.log(jsonState)
    //   const modifiedDistrictList = {
    //     ...jsonState,
    //     countries: jsonState.countries?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
    //     // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
    //   };
    //   state.loading = false;
    //   state.CategoryDetails = action.payload;
    //   state.partnerType = modifiedDistrictList;
    //   state.error = {};
    // },
    [updateCategoryDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteCategory.pending]: (state) => {
      state.loading = true;
    },
    [deleteCategory.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.subcategories;
      const modifiedDistrictList = {
        ...jsonState,
        subcategories: jsonState.subcategories?.filter(
          (subcategories) => subcategories._id !== action.payload
        ),
      };
      state.loading = false;
      state.subcategories = modifiedDistrictList;
      state.error = {};
    },
    [deleteCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = categorySlice.actions;

export default categorySlice.reducer;
