import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';

import { getAllUsers } from 'src/server/api/user';
import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import {
  useTable,
  // emptyRows,
  TableNoData,
  // TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
} from 'src/components/table';

import UserTableRow from 'src/sections/seller/seller-table-row';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function User() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  // const theme = useTheme();
  const settings = useSettingsContext();
  const router = useRouter();
  const table = useTable({ defaultOrderBy: 'createdAt' });
  const confirm = useBoolean();

  const { loading, userList } = useSelector((state) => ({
    loading: state.user.loading,
    userList: state.user.users,
  }));
  console.log(userList);

  const identifier = 'user';
  const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);

  const [tableData, setTableData] = useState([]);
  // const totalPage = userList?.total;

  const TABLE_HEAD = [
    { id: 'name', label: ' Name' },
    { id: 'phone', label: 'Phone' },
    { id: 'Business Name', label: 'Business Name' },

    { id: 'GST Number', label: 'GST Number' },
    { id: 'is_verified', label: 'Verify Seller' },
    { id: '' },
  ];

  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      search: '',
      sort: '',
      dispatch,
      limit: storedRowsPerPage || table.rowsPerPage,
      is_seller: true,
      district: '',
      state: '',
      city: '',
    };
    dispatch(getAllUsers(credentials));
  }, [dispatch, table.page, table.rowsPerPage, storedRowsPerPage]);

  useEffect(() => {
    setTableData(userList?.users);
  }, [userList]);

  const dataInPage = [];

  const canReset = false;

  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData?.filter((row) => row.id !== id);
      enqueueSnackbar('Delete success!');
      setTableData(deleteRow);
      table.onUpdatePageDeleteRow(dataInPage?.length);
    },
    [dataInPage?.length, enqueueSnackbar, table, tableData]
  );

  const handleDeleteRows = () => {};

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.details(id));
    },
    [router]
  );

  const handleButton = () => {
    router.push(paths.dashboard.users);
  };

  return (
    <>
      <MetaHelmet title="User" />

      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <Card sx={{ position: 'relative' }}>
          {/* <UserTableToolbar
            filters=""
            setFilterView={setFilterView}
            filterView={filterView}
            onFilters={(e, searchTextValue) => setSearchText(searchTextValue)}
          /> */}
          <Stack sx={{ padding: '1rem' }}>
            <Typography sx={{ fontWeight: '700', fontSize: '18px' }}>Recent Sellers</Typography>
          </Stack>

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) => {
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                );
              }}
              action={
                <Stack direction="row">
                  <Tooltip title="Sent">
                    <IconButton color="primary">
                      <Iconify icon="iconamoon:send-fill" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Download">
                    <IconButton color="primary">
                      <Iconify icon="eva:download-outline" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Print">
                    <IconButton color="primary">
                      <Iconify icon="solar:printer-minimalistic-bold" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={confirm.onTrue}>
                      <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            />

            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={table.selected?.length}
                  onSort={table.onSort}
                  // onSelectAllRows={(checked) =>
                  //   table.onSelectAllRows(
                  //     checked,
                  //     tableData.map((row) => row.id)
                  //   )
                  // }
                />

                <TableBody>
                  {(tableData || []).map((row, index) => (
                    <UserTableRow
                      index={index}
                      page={table.page + 1}
                      rowsPerPage={storedRowsPerPage || table.rowsPerPage || 5}
                      key={row.id}
                      row={row}
                      selected={table?.selected?.includes(row.id)}
                      onSelectRow={() => table.onSelectRow(row.id)}
                      onViewRow={() => handleViewRow(row.id)}
                      onEditRow={() => handleEditRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      loading={loading}
                      colSpan={TABLE_HEAD?.length}
                    />
                  ))}
                </TableBody>
                {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}

                <TableNoData notFound={notFound} />
              </Table>
            </Scrollbar>
          </TableContainer>

          {/* <TablePaginationCustom
            count={totalPage || 0}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
            identifier={identifier}
          /> */}
          <Stack sx={{ justifyContent: 'flex-end' }}>
            <Button
              onClick={handleButton}
              sx={{
                fontSize: '15px',
                '&:hover': { backgroundColor: 'unset' },
                margin: '10px',
                justifyContent: 'flex-end',
              }}
            >
              View All <KeyboardArrowRightIcon />
            </Button>
          </Stack>
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}
