import { configureStore } from '@reduxjs/toolkit';

import appReducer from './app';
import cityReducer from './city';
import authReducer from './auth';
import userReducer from './user';
import stateReducer from './state';
import commonReducer from './common';
import productReducer from './product';
import CountryReducer from './country';
import categoryReducer from './category';
import DistrictReducer from './district';
import settingsReducer from './settings';
import dashboardReducer from './dashboard';
import marketPlaceReducer from './marketPlace';
import marketPriceReducer from './marketPrice';

export default configureStore({
  reducer: {
    common: commonReducer,
    auth: authReducer,
    app: appReducer,
    dashboard: dashboardReducer,
    country: CountryReducer,
    district: DistrictReducer,
    settings: settingsReducer,
    state: stateReducer,
    city: cityReducer,
    user: userReducer,
    product: productReducer,
    category: categoryReducer,
    marketPlace: marketPlaceReducer,
    marketPrice: marketPriceReducer,
  },
});
