import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSettingsDetails } from 'src/server/api/settings';

export const CurrencySwitch = () => {
  const { settingsData } = useSelector((state) => ({
    settingsData: state.settings.settingsDetails,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    const credentials = {
      dispatch,
    };
    dispatch(getSettingsDetails(credentials));
  }, [dispatch]);

  const currency = settingsData?.settings?.defaultCurrencySymbol;

  // Return the currency if it exists, otherwise return a default value
  return currency || 'DefaultCurrencySymbol';
};
