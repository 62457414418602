import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createMarketPrice,
  getAllMarketPrice,
  deleteMarketPrice,
  getMarketPriceDetails,
  createBulkMarketPrice,
  updateMarketPriceDetails,
  updateBulkMarketPriceDetails,
  getAllBulkCategoryMarketPrice,
} from '../api/marketPrice';

export const marketPriceSlice = createSlice({
  name: 'marketPrice',
  initialState: {
    loading: false,
    error: {},
    marketprices: {},
    CategoryMarketPrice: {},
    Bulkmarketprices: {},
    marketBulkPriceDetails: {},
    marketPriceDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create product details
    [createMarketPrice.pending]: (state) => {
      state.loading = true;
    },
    [createMarketPrice.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.marketprices;
      const newProductList = jsonState?.marketprices;

      const modifiedProductList = {
        ...jsonState,
        marketprices: Array.isArray(newProductList)
          ? [action.payload, ...newProductList]
          : [action.payload],
      };
      state.loading = false;
      state.marketPriceDetails = action.payload;
      state.marketprices = modifiedProductList;
      state.error = {};
    },
    [createMarketPrice.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all product
    [getAllMarketPrice.pending]: (state) => {
      state.loading = true;
    },
    [getAllMarketPrice.fulfilled]: (state, action) => {
      state.loading = false;
      state.marketprices = action.payload;
      state.error = {};
    },
    [getAllMarketPrice.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all bulk
    [getAllBulkCategoryMarketPrice.pending]: (state) => {
      state.loading = true;
    },
    [getAllBulkCategoryMarketPrice.fulfilled]: (state, action) => {
      state.loading = false;
      state.CategoryMarketPrice = action.payload;
      state.error = {};
    },
    [getAllBulkCategoryMarketPrice.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // get single product details
    [getMarketPriceDetails.pending]: (state) => {
      state.loading = true;
    },
    [getMarketPriceDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.marketPriceDetails = action.payload;
      state.error = {};
    },
    [getMarketPriceDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update product details
    [updateMarketPriceDetails.pending]: (state) => {
      state.loading = true;
    },

    [updateMarketPriceDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.marketprices;
      const modifiedProductList = {
        ...jsonState,
        marketprices: jsonState.marketprices?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      state.marketPriceDetails = action.payload;
      state.marketprices = modifiedProductList;
      state.error = {};
    },

    [updateMarketPriceDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // delete product
    [deleteMarketPrice.pending]: (state) => {
      state.loading = true;
    },
    [deleteMarketPrice.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.marketprices;
      const modifiedProductList = {
        ...jsonState,
        marketprices: jsonState.marketprices?.filter((product) => product._id !== action.payload),
      };

      state.loading = false;
      state.marketprices = modifiedProductList;
      state.error = {};
    },
    [deleteMarketPrice.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // bulk
    [createBulkMarketPrice.pending]: (state) => {
      state.loading = true;
    },
    [createBulkMarketPrice.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.marketprices;
      const newProductList = jsonState?.marketprices;

      const modifiedProductList = {
        ...jsonState,
        marketprices: [...newProductList, ...action.payload],
      };
      state.loading = false;
      // state.marketPriceDetails = action.payload;
      state.marketprices = modifiedProductList;
      state.error = {};
      state.loading = false;
      // state.marketprices = action.payload;
    },
    [createBulkMarketPrice.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // bulk
    [updateBulkMarketPriceDetails.pending]: (state) => {
      state.loading = true;
    },

    [updateBulkMarketPriceDetails.fulfilled]: (state, action) => {
      // const jsonState = covertToJSON(state)?.marketprices;
      // const modifiedProductList = {
      //   ...jsonState,
      //   marketprices: jsonState.marketprices?.map((i) =>
      //     i._id === action.payload._id ? action?.payload : i
      //   ),
      // };

      state.loading = false;
      state.marketBulkPriceDetails = action.payload;
      // state.marketprices = modifiedProductList;
      // state.error = {};
    },

    [updateBulkMarketPriceDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = marketPriceSlice.actions;

export default marketPriceSlice.reducer;
