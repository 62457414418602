import { useEffect } from 'react';

function useCopyEncryption() {
  useEffect(() => {
    const encryptText = (text) => btoa(text);

    const handleCopy = (event) => {
      const selection = window.getSelection();
      const encryptedText = encryptText(selection.toString());

      // Check if the selected text is within a paragraph
      if (selection.focusNode) {
        event.clipboardData.setData('text/plain', encryptedText);
        event.preventDefault();
      }
    };

    // const textElements = document.querySelectorAll('.qa-text');

    // // Attach the copy event listener to each text element
    // textElements?.forEach((element) => {
    //   element.addEventListener('copy', handleCopy);
    // });

    // Add event listener to document
    document.addEventListener('copy', handleCopy);

    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener('copy', handleCopy);
    };
  }, []); // Only run this effect once on component mount
}

export default useCopyEncryption;
