// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  LMS: '/academy',
};

// ----------------------------------------------------------------------

export const paths = {
  public: {
    landing: '/landing',
    privacy_policy: '/privacy-policy',
    academy_landing: ROOTS.LMS,
    course_view: (id) => `${ROOTS.LMS}/course/${id}`,
  },
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login/admin`,
    login_partner: `${ROOTS.AUTH}/login/partner`,
    login_school: `${ROOTS.AUTH}/login/school`,
    login_academy: `${ROOTS.AUTH}/login/academy`,

    register: `${ROOTS.AUTH}/register`,

    logout: `${ROOTS.AUTH}/logout`,

    partner_registration: `${ROOTS.AUTH}/partner-registration`,
    school_registration: `${ROOTS.AUTH}/school-registration`,
    academy_registration: `${ROOTS.AUTH}/academy-registration`,

    create_password: (type, token) => `${ROOTS.AUTH}/create-password/:${type}/:${token}`,
    forgot_password_email: (type, token) => `${ROOTS.AUTH}/password/reset/:${type}/:${token}`,
    forgot_password: `${ROOTS.AUTH}/forgot-password`,
    password_verification: (type, token) => `${ROOTS.AUTH}/invitation/:${type}/:${token}`,

    course_content_view: (id) => `${ROOTS.LMS}/course/content/${id}`,
    exams: `${ROOTS.LMS}/exams`,
    exams_register: (id) => `${ROOTS.LMS}/exams/register/${id}`,
    exams_view: (studentExam, exam) => `${ROOTS.LMS}/exams/${studentExam}/${exam}`,
    exams_over: (id) => `${ROOTS.LMS}/exams/${id}/over`,
    student_profile: `${ROOTS.LMS}/student-profile`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    onBoarding: '/on-boarding',

    profile: `${ROOTS.DASHBOARD}/profile`,

    country: `${ROOTS.DASHBOARD}/country`,
    district: `${ROOTS.DASHBOARD}/district`,
    state: `${ROOTS.DASHBOARD}/state`,
    city: `${ROOTS.DASHBOARD}/city`,
    products: `${ROOTS.DASHBOARD}/products`,
    products_create: `${ROOTS.DASHBOARD}/product/create`,
    product_update: (id) => `${ROOTS.DASHBOARD}/product/update/${id}`,
    product_view: (id) => `${ROOTS.DASHBOARD}/product/${id}`,

    users: `${ROOTS.DASHBOARD}/users`,
    user_create: `${ROOTS.DASHBOARD}/user/create`,
    user_view: (id) => `${ROOTS.DASHBOARD}/user/${id}`,

    category: `${ROOTS.DASHBOARD}/category`,
    marketPrice: `${ROOTS.DASHBOARD}/market-price`,
    settings: `${ROOTS.DASHBOARD}/settings`,
  },
};

export const AuthRoles = {
  admin: 'Admin',
  partner: 'Partner',
  school: 'School',
  academy: 'Student',
};
