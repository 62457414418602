import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { AuthRoles } from 'src/routes/routes/paths';

import SettingsPage from 'src/pages/Admin/settings';
import LoginAdmin from 'src/assets/other/loginnew.png';

import { SplashScreen } from 'src/components/loading-screen';

const AuthLayout = lazy(() => import('src/layouts/auth/authLayout'));
const LogoutPage = lazy(() => import('src/pages/Common/Auth/LogoutPage'));
const LoginAdminPage = lazy(() => import('src/pages/Common/Auth/Login'));

const NewPasswordPage = lazy(() => import('src/pages/Common/Auth/NewPassword'));
const ForgotPasswordPage = lazy(() => import('src/pages/Common/Auth/ForgotPassword'));
const AuthModernCompactLayout = lazy(() => import('src/layouts/auth/modern-compact'));

const CreatePassword = lazy(() => import('src/sections/auth/new-password-view'));

const CompactLayout = lazy(() => import('src/layouts/compact'));
const CountryPage = lazy(() => import('src/pages/Common/Country/CountryPage'));
const DistrictPage = lazy(() => import('src/pages/Common/District/District-list'));
const StatePage = lazy(() => import('src/pages/Common/State/State-list'));
const CityPage = lazy(() => import('src/pages/Common/City/City-list'));
const UserPage = lazy(() => import('src/pages/Common/User/User-list'));
const UserProfileView = lazy(() => import('src/pages/Common/User/UserProfileView'));
const ProfileView = lazy(() => import('src/pages/Common/Auth/profile/index'));

const ProductViewPage = lazy(() => import('src/pages/Common/Product/product-view'));

const ProductPage = lazy(() => import('src/pages/Common/Product/Product-tab-page'));
const ProductCreatePage = lazy(() => import('src/sections/product/product-crud-Page'));
const CategoryPage = lazy(() => import('src/pages/Common/Category/Category-card-list'));
const UserCreatePage = lazy(() => import('src/sections/user/UserCrudPage'));
const MarketPricePage = lazy(() => import('src/pages/Common/MarketPrice/Market-Price-Page'));

const Page500 = lazy(() => import('src/pages/Common/Static/500'));
const Page403 = lazy(() => import('src/pages/Common/Static/403'));
const Page404 = lazy(() => import('src/pages/Common/Static/404'));

const publicRoutes = [
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
      // { path: 'privacy-policy', element: <PrivacyPolicy /> },
    ],
  },
];

const authRoutes = [
  {
    path: 'auth',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),

    children: [
      {
        path: 'login',
        element: <Outlet />,
        children: [
          {
            path: 'admin',
            element: (
              <AuthLayout
                image={LoginAdmin}
                title="Authenticate as an administrator"
                userRole="admin"
              >
                <LoginAdminPage role={AuthRoles.admin} />
              </AuthLayout>
            ),
          },
        ],
      },

      {
        path: 'logout',
        element: <LogoutPage />,
      },
      {
        path: 'forgot-password',
        element: (
          <AuthModernCompactLayout>
            <ForgotPasswordPage />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'create-password/:type/:token',
        element: (
          <AuthModernCompactLayout>
            <NewPasswordPage type="create" />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'password/reset/:token',
        element: (
          <AuthModernCompactLayout>
            <NewPasswordPage type="reset" />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'create-password/:id',
        element: (
          <AuthModernCompactLayout>
            <CreatePassword />
          </AuthModernCompactLayout>
        ),
      },
      { path: '404', element: <Page404 isInner /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
];

// super admin
const adminRoutes = [
  {
    path: 'country',
    element: <CountryPage />,
  },
  {
    path: 'district',
    element: <DistrictPage />,
  },
  {
    path: 'state',
    element: <StatePage />,
  },
  {
    path: 'city',
    element: <CityPage />,
  },
  {
    path: 'products',
    element: <ProductPage />,
  },
  { path: 'product/create', element: <ProductCreatePage /> },
  { path: 'product/update/:id', element: <ProductCreatePage /> },
  { path: 'product/:id', element: <ProductViewPage /> },

  {
    path: 'users',
    element: <UserPage />,
  },
  { path: 'user/create', element: <UserCreatePage /> },
  { path: 'user/:id', element: <UserProfileView /> },

  {
    path: 'category',
    element: <CategoryPage />,
  },
  {
    path: 'market-price',
    element: <MarketPricePage />,
  },
  {
    path: 'settings',
    element: <SettingsPage />,
  },
  {
    path: 'profile',
    element: <ProfileView />,
  },
];

export { authRoutes, adminRoutes, publicRoutes };
