import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { getSettingsDetails, updateSettingsDetails } from 'src/server/api/settings';

import FormProvider, { RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function SettingsForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mdUp = useResponsive('up', 'md');

  const { settingsDetails, loading } = useSelector((state) => ({
    settingsDetails: state.settings.settingsDetails,
    loading: state.settings.loading,
  }));
  const [weightUnit, setWeightUnit] = useState('');

  const handleWeightUnitChange = (event) => {
    setWeightUnit(event.target.value);
  };
  const WeightUnitOptions = [
    { label: 'KG ', value: 'kg' },
    { label: 'TON ', value: 'ton' },
    { label: 'PACK ', value: 'pack' },
    { label: 'NOS ', value: 'nos' },
    { label: 'UNIT ', value: 'unit' },
  ];
  console.log(settingsDetails);
  const NewJobSchema = Yup.object().shape({
    contact_details: Yup.object().shape({
      mobile: Yup.string()
        .required('Phone required')
        .min(10, 'Phone must be at least 10 characters')
        .max(15, 'Phone must not exceed 15 characters'),
      email: Yup.string().email(),
      whatsApp: Yup.string()
        .min(10, 'Phone must be at least 10 characters')
        .max(15, 'Phone must not exceed 15 characters'),
    }),

    company: Yup.object().shape({
      name: Yup.string(),
      address: Yup.string(),
      description: Yup.string(),
    }),

    social_links: Yup.object().shape({
      whatsApp: Yup.string(),

      instagram: Yup.string(),
      facebook: Yup.string(),
      // linkedIn: Yup.string(),
      // x: Yup.string(),
    }),
    // defaultCurrency: Yup.string(),
    // defaultCurrencySymbol: Yup.string(),
    // exam_introduction: Yup.string(),
    // question_limit: Yup.number(),
  });

  const defaultValues = useMemo(
    () => ({
      company: {
        name: settingsDetails?.company?.name,
        address: settingsDetails?.company?.address,
        description: settingsDetails?.company?.description,
      },
      contact_details: {
        mobile: settingsDetails?.contact_details?.mobile,
        email: settingsDetails?.contact_details?.email,
        whatsApp: settingsDetails?.contact_details?.whatsApp,
      },
      social_links: {
        whatsApp: settingsDetails?.social_links?.whatsApp,
        instagram: settingsDetails?.social_links?.instagram,
        facebook: settingsDetails?.social_links?.facebook,
        // linkedIn: settingsDetails?.social_links?.linkedIn,
        // x: settingsDetails?.social_links?.x,
      },
      // defaultCurrency: settingsDetails?.defaultCurrency,
      // defaultCurrencySymbol: settingsDetails?.defaultCurrencySymbol,
      // exam_introduction: settingsDetails?.exam_introduction,
      // question_limit: settingsDetails?.question_limit,

      first_name: settingsDetails?.first_name,
      last_name: settingsDetails?.last_name,
      business_name: settingsDetails?.business_name,
    }),
    [settingsDetails]
  );
  useEffect(() => {
    if (settingsDetails?._id) {
      setWeightUnit(settingsDetails?.weight_unit);
    } else {
      setWeightUnit('');
    }
  }, [settingsDetails?._id, settingsDetails?.weight_unit]);
  const methods = useForm({
    resolver: yupResolver(NewJobSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (settingsDetails) {
      reset(defaultValues);
    }
  }, [settingsDetails, defaultValues, reset]);

  useEffect(() => {
    const credentials = {
      dispatch,
    };
    dispatch(getSettingsDetails(credentials));
  }, [dispatch]);
  console.log(settingsDetails?._id);
  // const onSubmit = handleSubmit(async (data) => {
  //   const settingsId = settingsDetails?._id;

  //   const credentials = {
  //     state: data,

  //     weight_unit: weightUnit,
  //     // ...settingsDetails

  //     settingsId,
  //     dispatch,
  //     navigate,
  //   };
  //   dispatch(updateSettingsDetails(credentials));
  // });

  const onSubmit = handleSubmit(async (data) => {
    const settingsId = settingsDetails?._id;

    const credentials = {
      state: { ...data, weight_unit: weightUnit },
      settingsId,
      dispatch,
      navigate,
    };
    dispatch(updateSettingsDetails(credentials));
  });

  const yourContact = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Company
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Company details{' '}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="company.name"
                label="Name"
                type="text"
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="company.address"
                label="Address"
                type="text"
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="company.description"
                label="Description"
                type="text"
                multiline
                row={4}
              />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const contactDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Contact Details{' '}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Modify the contact details in this section{' '}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="contact_details.mobile"
                label="Phone"
                type="number"
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="contact_details.email"
                label="Email"
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="contact_details.whatsApp"
                label="Whatsapp Number"
                type="number"
              />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const socialLink = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Social Links{' '}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Modify the social links if necessary{' '}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          <Stack direction="column" spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="social_links.whatsApp"
                label="Whatsapp"
                type="text"
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="social_links.instagram"
                label="Instagram"
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="social_links.facebook"
                label="Facebook"
              />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );
  const WeightUnit = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Weight Unit{' '}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Change weight unit of market price{' '}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          <Stack direction="row" spacing={3} sx={{ p: 3, width: '100%' }}>
            <FormControl required name="weight_unit" sx={{ width: '100%' }}>
              <InputLabel sx={{ backgroundColor: 'white' }}>Weight Unit</InputLabel>
              <Select value={weightUnit} onChange={handleWeightUnitChange} width="100%">
                {WeightUnitOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  // const examDetails = (
  //   <>
  //     {mdUp && (
  //       <Grid md={4}>
  //         <Typography variant="h6" sx={{ mb: 0.5 }}>
  //           Exam
  //         </Typography>
  //         <Typography variant="body2" sx={{ color: 'text.secondary' }}>
  //           Exam Details
  //         </Typography>
  //       </Grid>
  //     )}

  //     <Grid xs={12} md={8}>
  //       <Card>
  //         {!mdUp && <CardHeader title="Details" />}

  //         <Stack direction="column" spacing={3} sx={{ p: 3 }}>
  //           <Stack spacing={1.5} width="100%">
  //             <RHFTextField
  //               InputLabelProps={{ shrink: true }}
  //               name="exam_introduction"
  //               label="Exam Introduction"
  //               multiline={4}
  //             />
  //           </Stack>
  //           <Stack spacing={1.5}>
  //             <RHFTextField
  //               InputLabelProps={{ shrink: true }}
  //               name="question_limit"
  //               label="Questions per page"
  //             />
  //           </Stack>
  //         </Stack>
  //       </Card>
  //     </Grid>
  //   </>
  // );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Stack sx={{ width: '100%' }} />
        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting || loading}
          sx={{ ml: 2, width: '250px', backgroundColor: '#106E58', color: 'white' }}
        >
          Save Changes
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {WeightUnit}
        {yourContact}

        {contactDetails}

        {socialLink}

        {/* {examDetails} */}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
