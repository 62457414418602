import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const getAllUsers = createAsyncThunk(
  'user/list',
  async ({ page, search, sort, dispatch, limit, is_seller, district, state, city }) => {
    try {
      const response = await get(
        `/user/admin/all?page=${page && page}&search=${search && search}&limit=${limit || 5}&sort=${
          sort || ''
        }&is_seller=${is_seller || ''}&district=${district || ''}&state=${state || ''}&city=${
          city || ''
        }`
      );
      console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const createUser = createAsyncThunk('user/create', async ({ state, dispatch, router }) => {
  try {
    const URL = `/user/admin/new`;
    const response = await post(URL, state);
    if (response) {
      router.push({
        pathname: paths.dashboard.users,
        query: {},
      });

      dispatch(activeSnack({ type: 'success', message: 'User created successfully' }));
    } else {
      dispatch(activeSnack({ type: 'error', message: 'Something went wrong' }));
    }
    return response;
  } catch (error) {
    dispatch(
      activeSnack({
        type: 'error',
        message: error?.response?.data?.message || 'An error occurred',
      })
    );
    throw error;
  }
});
export const getUserDetails = createAsyncThunk('user/single', async ({ userId, dispatch }) => {
  try {
    console.log('ID:', userId);
    const response = await get(`/user/admin/${userId}`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateUserDetails = createAsyncThunk(
  'user/update',
  async ({ state, userId, dispatch }) => {
    try {
      const URL = `/user/admin/${userId}`;
      // cc
      const response = await put(URL, state);
      if (response) {
        // router.push({
        //   pathname: paths.dashboard.products,
        //   query: {},
        // });

        dispatch(activeSnack({ type: 'success', message: 'User updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteUser = createAsyncThunk('user/delete', async ({ userId, dispatch }) => {
  try {
    const response = await del(`/user/admin/${userId}`);
    if (response) {
      dispatch(activeSnack({ type: 'success', message: 'User deleted Successfully' }));
      return response?._id;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
