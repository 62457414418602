import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createMarketPrice = createAsyncThunk(
  'market-price/create',
  async ({ state, dispatch }) => {
    try {
      const URL = `/market-price/admin/new`;
      const response = await post(URL, state);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Market Price created successfully' }));
      } else {
        dispatch(activeSnack({ type: 'error', message: 'Something went wrong' }));
      }
      return response;
    } catch (error) {
      dispatch(
        activeSnack({
          type: 'error',
          message: error?.response?.data?.message || 'An error occurred',
        })
      );
      throw error;
    }
  }
);

export const getAllMarketPrice = createAsyncThunk(
  'market-price/list',
  async ({
    page,
    search,
    limit,

    dispatch,

    market_place,
  }) => {
    try {
      const response = await get(
        `/market-price/admin/all?page=${page && page}&search=${search && search}&limit=${
          limit || 5
        }&market_place=${market_place || ''}`
      );
      console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getMarketPriceDetails = createAsyncThunk(
  'market-price/single',
  async ({ marketPriceId, dispatch }) => {
    try {
      console.log('ID:', marketPriceId);
      const response = await get(`/market-price/admin/${marketPriceId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateMarketPriceDetails = createAsyncThunk(
  'market-price/update',
  async ({ state, marketPriceId, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/market-price/admin/${marketPriceId}`;
      // cc
      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        // Uncomment the next line if you intend to redirect
        // router.push({
        //   pathname: paths.dashboard.products,
        //   query: {},
        // });

        dispatch(activeSnack({ type: 'success', message: 'Market Price updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteMarketPrice = createAsyncThunk(
  'market-price/delete',
  async ({ marketPriceId, dispatch }) => {
    try {
      const response = await del(`/market-price/admin/${marketPriceId}`);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Market Price deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const createBulkMarketPrice = createAsyncThunk(
  'market-price/bulk/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `market-price/admin/new/bulk`;
      const response = await post(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        dispatch(
          getAllMarketPrice({
            page: 1,
            search: '',
            limit: sessionStorage.getItem('rowsPerPage_marketPrice'),

            dispatch,
            market_place: state?.market_place,
          })
        );
        dispatch(
          activeSnack({ type: 'success', message: 'Bulk Market Price created successfully' })
        );
      } else {
        dispatch(activeSnack({ type: 'error', message: 'Something went wrong' }));
      }
      return response;
    } catch (error) {
      dispatch(
        activeSnack({
          type: 'error',
          message: error?.response?.data?.message || 'An error occurred',
        })
      );
      throw error;
    }
  }
);
export const updateBulkMarketPriceDetails = createAsyncThunk(
  'market-price/bulk/update',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/market-price/admin/update/bulk`;
      // cc
      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        // Uncomment the next line if you intend to redirect
        // router.push({
        //   pathname: paths.dashboard.products,
        //   query: {},
        // });

        dispatch(
          activeSnack({ type: 'success', message: 'Bulk Market Price updated Successfully' })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getAllBulkCategoryMarketPrice = createAsyncThunk(
  'market-price/category/list',
  async ({ market_place, dispatch }) => {
    try {
      const response = await get(`/market-price/admin/category/all/${market_place}`);
      console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
