import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createCategory = createAsyncThunk(
  'subcategory/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/subcategory/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(
          getAllCategory({
            page: 1,
            search: '',
            dispatch,
            limit: sessionStorage.getItem('rowsPerPage_category'),
            state: '',
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'Category created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllCategory = createAsyncThunk(
  'subcategory/list',
  async ({ page, search, dispatch, limit }) => {
    try {
      const response = await get(
        `/subcategory/admin/all?page=${page && page}&search=${search && search}&limit=${limit || 5}`
      );
      console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getAllMainCategory = createAsyncThunk(
  'category/list',
  async ({ page, search, dispatch, limit }) => {
    try {
      const response = await get(
        `/category/admin/all?page=${page && page}&search=${search && search}&limit=${limit || 5}`
      );
      console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getCategoryDetails = createAsyncThunk(
  'subcategory/single',
  async ({ categoryId, dispatch }) => {
    try {
      console.log('ID:', categoryId);
      const response = await get(`/subcategory/admin/${categoryId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateCategoryDetails = createAsyncThunk(
  'subcategory/update',
  async ({ state, categoryId, dispatch, handleClose, handleClear, handleMenuClose }) => {
    try {
      const URL = `/subcategory/admin/${categoryId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleMenuClose) {
          handleMenuClose();
        }
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        dispatch(
          getAllCategory({
            page: 1,
            search: '',
            dispatch,
            limit: sessionStorage.getItem('rowsPerPage_category'),
            state: '',
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'Category updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteCategory = createAsyncThunk(
  'subcategory/delete',
  async ({ categoryId, dispatch, handleMenuClose }) => {
    try {
      const response = await del(`/subcategory/admin/${categoryId}/`);
      if (response) {
        if (handleMenuClose) {
          handleMenuClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Category deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
