import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createCity = createAsyncThunk(
  'city/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/city/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(
          getAllCity({
            page: 1,
            search: '',
            dispatch,
            limit: sessionStorage.getItem('rowsPerPage_city'),
            district: '',
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'City created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllCity = createAsyncThunk(
  'city/list',
  async ({ page, search, dispatch, limit, district }) => {
    try {
      const response = await get(
        `/city/all?page=${page && page}&search=${search && search}&limit=${limit || 5}&district=${
          district || ''
        }`
      );
      console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getCityDetails = createAsyncThunk('city/single', async ({ cityId, dispatch }) => {
  try {
    console.log('ID:', cityId);
    const response = await get(`/city/admin/${cityId}`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateCityDetails = createAsyncThunk(
  'city/update',
  async ({ state, cityId, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/city/admin/${cityId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        dispatch(
          getAllCity({
            page: 1,
            search: '',
            dispatch,
            limit: sessionStorage.getItem('rowsPerPage_city'),
            district: '',
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'City updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteCity = createAsyncThunk('city/delete', async ({ cityId, dispatch }) => {
  try {
    const response = await del(`/city/admin/${cityId}`);
    if (response) {
      dispatch(activeSnack({ type: 'success', message: 'City deleted Successfully' }));
      return response?._id;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
