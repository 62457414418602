import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createProduct,
  getAllProduct,
  deleteProduct,
  getProductDetails,
  updateProductDetails,
} from '../api/product';

export const productSlice = createSlice({
  name: 'product',
  initialState: {
    loading: false,
    error: {},
    products: {},
    productDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create product details
    [createProduct.pending]: (state) => {
      state.loading = true;
    },
    [createProduct.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.products;
      const newProductList = jsonState?.products;

      const modifiedProductList = {
        ...jsonState,
        products: Array.isArray(newProductList)
          ? [action.payload, ...newProductList]
          : [action.payload],
      };
      state.loading = false;
      state.productDetails = action.payload;
      state.products = modifiedProductList;
      state.error = {};
    },
    [createProduct.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all product
    [getAllProduct.pending]: (state) => {
      state.loading = true;
    },
    [getAllProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.products = action.payload;
      state.error = {};
    },
    [getAllProduct.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single product details
    [getProductDetails.pending]: (state) => {
      state.loading = true;
    },
    [getProductDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.productDetails = action.payload;
      state.error = {};
    },
    [getProductDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update product details
    [updateProductDetails.pending]: (state) => {
      state.loading = true;
    },

    [updateProductDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.products;
      const modifiedProductList = {
        ...jsonState,
        products: jsonState.products?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      state.productDetails = action.payload;
      state.products = modifiedProductList;
      state.error = {};
    },

    [updateProductDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // delete product
    [deleteProduct.pending]: (state) => {
      state.loading = true;
    },
    [deleteProduct.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.products;
      const modifiedProductList = {
        ...jsonState,
        products: jsonState.products?.filter((product) => product._id !== action.payload),
      };

      state.loading = false;
      state.products = modifiedProductList;
      state.error = {};
    },
    [deleteProduct.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = productSlice.actions;

export default productSlice.reducer;
