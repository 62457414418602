import { useSnackbar } from 'notistack';
import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TourIcon from '@mui/icons-material/Tour';
import BalconyIcon from '@mui/icons-material/Balcony';
import SettingsIcon from '@mui/icons-material/Settings';
import LocationCityIcon from '@mui/icons-material/LocationCity';

import { paths } from 'src/routes/routes/paths';

import { getDashboardData } from 'src/server/api/dashboard';

import Label from 'src/components/label';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

// ----------------------------------------------------------------------

export function useNavData() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { dashboardData } = useSelector((state) => ({
    // profile: state.partner.profile,
    dashboardData: state.dashboard.dashboardData,
  }));
  useEffect(() => {
    const credentials = {
      enqueueSnackbar,
    };
    dispatch(getDashboardData(credentials));
  }, [dispatch, enqueueSnackbar]);
  const counts = dashboardData?.totalProductsCount;

  console.log(counts);

  // useEffect(() => {
  //   const credentials = {
  //     userType: role,
  //     enqueueSnackbar,
  //   };
  //   dispatch(getSidebarItemCountData(credentials));
  // }, [dispatch, enqueueSnackbar, role]);

  // totalPartnerCount(pin):0
  // totalPartnerTypesCount(pin):3
  // totalPartnerCommissionsCount(pin):12
  // totalLabModelsCount(pin):4
  // totalEarningsCount(pin):2
  // totalSchoolCount(pin):12
  // totalSchoolUserCount(pin):12
  // totalSupportMaterialCount(pin):5
  // totalStudentsCount(pin):12
  // totalCourseCount(pin):5
  // totalExamCount(pin):4
  // totalStudentCertificateCount(pin):2
  // totalCountriesCount(pin):4

  //   totalEarningsCount: 2
  // totalSalesCount: 6
  // totalSchoolCount: 2

  // sidebar data block
  const adminSideBarData = useMemo(
    () => [
      {
        subheader: 'Overview',
        items: [
          { title: 'Dashboard', path: `${paths.dashboard.root}/app`, icon: icon('ic_dashboard') },
        ],
      },

      {
        subheader: 'management',
        items: [
          { title: 'User', path: paths.dashboard.users, icon: icon('ic_user') },
          {
            title: 'Product',
            path: `${paths.dashboard.products}?search=0`,
            icon: icon('ic_product'),
            info: (
              <Label color="default" sx={{ backgroundColor: 'default', color: 'black' }}>
                {counts}
              </Label>
            ),
          },
          { title: 'Category', path: paths.dashboard.category, icon: icon('ic_file') },
          { title: 'Market Price', path: paths.dashboard.marketPrice, icon: icon('ic_analytics') },
          { title: 'Settings', path: paths.dashboard.settings, icon: <SettingsIcon /> },
        ],
      },
      {
        subheader: 'LGS',
        items: [
          {
            title: 'State',
            path: paths.dashboard.state,
            icon: <BalconyIcon />,
          },
          {
            title: 'District ',
            path: paths.dashboard.district,
            icon: <TourIcon />,
          },
          {
            title: 'City',
            path: paths.dashboard.city,
            icon: <LocationCityIcon />,
            // info: <Label color="warning">{counts?.totalEarningsCount}</Label>,
          },
        ],
      },
    ],
    [counts]
  );

  const data = adminSideBarData;

  return data;
}

// export function useNavData() {
//   const data = useMemo(() => adminSideBarData, []);

//   return data;
// }

// const ICONS = {
//   job: icon('ic_job'),
//   blog: icon('ic_blog'),
//   chat: icon('ic_chat'),
//   mail: icon('ic_mail'),
//   user: icon('ic_user'),
//   file: icon('ic_file'),
//   lock: icon('ic_lock'),
//   tour: icon('ic_tour'),
//   order: icon('ic_order'),
//   label: icon('ic_label'),
//   blank: icon('ic_blank'),
//   kanban: icon('ic_kanban'),
//   folder: icon('ic_folder'),
//   banking: icon('ic_banking'),
//   booking: icon('ic_booking'),
//   invoice: icon('ic_invoice'),
//   product: icon('ic_product'),
//   calendar: icon('ic_calendar'),
//   disabled: icon('ic_disabled'),
//   external: icon('ic_external'),
//   menuItem: icon('ic_menu_item'),
//   ecommerce: icon('ic_ecommerce'),
//   analytics: icon('ic_analytics'),
//   dashboard: icon('ic_dashboard'),
// };
