import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createDistrict = createAsyncThunk(
  'district/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/district/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(
          getAllDistrict({
            page: 1,
            search: '',
            dispatch,
            limit: sessionStorage.getItem('rowsPerPage_district'),
            state: '',
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'district created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllDistrict = createAsyncThunk(
  'district/list',
  async ({ page, search, dispatch, limit, state }) => {
    try {
      const response = await get(
        `/district/all?page=${page && page}&search=${search && search}&limit=${limit || 5}&state=${
          state || ''
        }`
      );
      console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getDistrictDetails = createAsyncThunk(
  'district/single',
  async ({ districtId, dispatch }) => {
    try {
      console.log('ID:', districtId);
      const response = await get(`/district/admin/${districtId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateDistrictDetails = createAsyncThunk(
  'district/update',
  async ({ state, districtId, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/district/admin/${districtId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        dispatch(
          getAllDistrict({
            page: 1,
            search: '',
            dispatch,
            limit: sessionStorage.getItem('rowsPerPage_district'),
            state: '',
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'District updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteDistrict = createAsyncThunk(
  'district/delete',
  async ({ districtId, dispatch }) => {
    try {
      const response = await del(`/district/admin/${districtId}/`);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'District deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
