import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createDistrict,
  getAllDistrict,
  deleteDistrict,
  getDistrictDetails,
  updateDistrictDetails,
} from '../api/district';

export const districtSlice = createSlice({
  name: 'district',
  initialState: {
    loading: false,
    error: {},
    // staff
    districts: {},
    staffDropList: [],
    DistrictDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create staff details
    [createDistrict.pending]: (state) => {
      state.loading = true;
    },
    [createDistrict.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.districts;
      const newDistrictsList = jsonState?.districts;

      const modifiedDistrictList = {
        ...jsonState,
        districts: Array.isArray(newDistrictsList)
          ? [action.payload, ...newDistrictsList]
          : [action.payload],
      };
      state.loading = false;
      state.DistrictDetails = action.payload;
      state.districts = modifiedDistrictList;
      state.error = {};
    },
    [createDistrict.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all districts
    [getAllDistrict.pending]: (state) => {
      state.loading = true;
    },
    [getAllDistrict.fulfilled]: (state, action) => {
      state.loading = false;
      state.districts = action.payload;
      state.error = {};
    },
    [getAllDistrict.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single staff details
    [getDistrictDetails.pending]: (state) => {
      state.loading = true;
    },
    [getDistrictDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.DistrictDetails = action.payload;
      state.error = {};
    },
    [getDistrictDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update staff details
    [updateDistrictDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateDistrictDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.DistrictDetails = action.payload;
    //   state.error = {};
    // },
    [updateDistrictDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.districts;
      const modifiedDistrictList = {
        ...jsonState,
        districts: jsonState.districts?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      // state.districts = modifiedDistrictList;
      state.DistrictDetails = action.payload;
      state.districts = modifiedDistrictList;
      state.error = {};
    },
    // [updateDistrictDetails.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   const jsonState = covertToJSON(state)?.districts;
    //   console.log(jsonState)
    //   const modifiedDistrictList = {
    //     ...jsonState,
    //     countries: jsonState.countries?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
    //     // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
    //   };
    //   state.loading = false;
    //   state.DistrictDetails = action.payload;
    //   state.partnerType = modifiedDistrictList;
    //   state.error = {};
    // },
    [updateDistrictDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteDistrict.pending]: (state) => {
      state.loading = true;
    },
    [deleteDistrict.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.districts;
      const modifiedDistrictList = {
        ...jsonState,
        districts: jsonState.districts?.filter((districts) => districts._id !== action.payload),
      };
      state.loading = false;
      state.districts = modifiedDistrictList;
      state.error = {};
    },
    [deleteDistrict.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = districtSlice.actions;

export default districtSlice.reducer;
